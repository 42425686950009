import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Container, Row, Col } from 'reactstrap';
import Alert from 'reactstrap/lib/Alert';

import { useIntl, LoadingSpinner, Masonry, NativeSlider } from '@ttstr/components';
import { receiveProducts } from '@ttstr/actions';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';

const Home: React.FC = () => {
  const { t } = useTranslation();
  const { products, loading } = useShallowEqualSelector(mapStateToProps);
  const { receiveProducts } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    const abortController = new AbortController();
    receiveProducts(undefined, { signal: abortController.signal });
    return () => abortController.abort();
  }, [language]);

  return (
    <article className="my-5">
      <Helmet>
        <body className="product-listing-page home-page inverse-navbar" />
      </Helmet>

      <Container
        fluid
        className="m-0 align-items-center justify-content-center d-flex text-center pt-0 px-5 mh-100 flex-column"
      >
        <h1 className="header">KOOK</h1>
        <div className="video-container justify-content-center align-items-center mt-3 mb-5">
          <iframe
            className="responsive-video"
            src="https://www.youtube-nocookie.com/embed/joDFmeadAdo?si=DWqzbDF5I91B1Z2U&amp;controls=0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
        <div className="video-container justify-content-center align-items-center mt-3 mb-5">
          <iframe
            className="responsive-video"
            src="https://www.youtube-nocookie.com/embed/QjpXB8fwalw?si=hhngyIMnLWQrpPjp&amp;controls=0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
        </div>
      </Container>

      <Container className="">
        {loading ? (
          // if loading
          <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />
        ) : // if no products
        products.length === 0 ? (
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show products
          <Masonry
            id="product-listing"
            products={products.filter((p) => p.tag_ids.includes(6208))}
            filters="off"
            showSubtitle
          />
        )}
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { products, loading } = state.Products;
  return {
    products,
    loading,
  };
};

const mapDispatchToProps = {
  receiveProducts,
};

export default React.memo(Home);
